import { DisplayDataGrid, DisplayDataItem, Row } from '@elotech/components';
import React from 'react';

import { Processo } from '../../../types/Processo';

type Props = {
  processo: Processo;
};

export const ProcessoDetailDenunciante: React.FC<Props> = ({ processo }) => {
  return (
    <DisplayDataGrid column={true}>
      <Row>
        <DisplayDataItem md={5} title="Nome">
          {processo?.denunciante?.nome}
        </DisplayDataItem>
        <DisplayDataItem md={5} title="CNPJ/CPF">
          {processo?.denunciante?.cnpjCpf}
        </DisplayDataItem>
      </Row>
    </DisplayDataGrid>
  );
};
